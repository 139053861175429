import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import ButtonLinkWrapper from "../ui/btnLinkWrapper/ButtonLinkWrapper";
import MyButton from "../ui/button/MyButton";
import Arrow from "../ui/btnArrow/Arrow";

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  textAlingn: `center`,
  color: `white`
};

const paragraphStyles = {
  marginBottom: 48,
  color: `white`
};

const NotFoundPage = () => {
  return (
    <Layout>
      <div style={{ padding: `40px`, borderBottom: `1px solid white`, width: `100%` }}>
        <title style={{color: `white`}}>Сторінку не знайдено</title>
        <h1 style={headingStyles}>404</h1>
        <p style={paragraphStyles}>
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          Можливо, сторінка, яку Ви запитували, була перенесена або видалена.
          <br />
          <br />
          <Link to="/" style={{ textDecoration: "none" }}>
            <ButtonLinkWrapper>
              <MyButton isBlackBg={true}>НА ГОЛОВНУ</MyButton>
              <Arrow isBlackBg={true} />
            </ButtonLinkWrapper>
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
